$(() => {
  $(window).on('scroll', function (){
    var elem = $('.fadeInUpTrigger');
    elem.each(function () {
    
      var elemOffset = $(this).offset().top;
      var scrollPos = $(window).scrollTop();
      var wh = $(window).height();
  
      if(scrollPos > elemOffset - wh + (wh / 4) ){
        $('.top-all_download').addClass('active');
      }else{
        $('.top-all_download').removeClass('active');
      }
    });
  });

  // 「資料を選択」のチェックの挙動
  $(".document_ids-checkbox").click(function(){
    $target = $(this);
    num = Number($("#checked_document_ids_count").text());

    if ($target.prop("checked")) {
      // on
      $("#checked_document_ids_count").text(num + 1);
      if(num === 0) {
        $("#bulk_download").prop("disabled", "");
        $("#bulk_download").removeClass("disabled");
      }
    } else {
      // off
      $("#checked_document_ids_count").text(num - 1);
      if(num - 1 === 0) {
        $("#bulk_download").prop("disabled", "disabled");
        $("#bulk_download").addClass("disabled");
      }
    }
  });
});
